import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";
import PointText from "src/components/PointText";
import logo from "src/images/logo-white-small.svg";

const width = ["100%", "90%", "90%", "90%", "50%"];

const Page: React.SFC = () => {
  return (
    <div
      sx={{
        margin: "auto",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        ml: "15vw",
        marginBottom: "10vh",
        mt: "20vh",
      }}
    >
      <div
        sx={{
          mr: [7],
        }}
      >
        <PointText text="что делаем" />
        <div
          sx={{
            height: 330,
          }}
        >
          <Styled.img
            sx={{
              height: "100%",
              width: "auto",
            }}
            src={logo}
          />
        </div>
      </div>
    </div>
  );
};

export default Page;
