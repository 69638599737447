import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled, useThemeUI } from "theme-ui";
import { PageProps } from "gatsby";
import AboutUsHeader from "src/routes/aboutUs/AboutUsHeader";
import Seo from "src/components/Seo";
import MainHeader from "src/components/MainHeader";
import AboutUsText from "src/routes/aboutUs/AboutText";
import Tape from "src/components/Tape";
import ImagesWrapper from "src/routes/aboutUs/ImagesWrapper";
import SectionHeader from "src/components/SectionHeader";
import littleArrow from "src/images/littleArrow.svg";
import { Link } from "gatsby";

const width = ["100%", "90%", "90%", "90%", "50%"];

const Page: React.SFC<PageProps> = () => {
  const context = useThemeUI();

  return (
    <div
      sx={{
        overflowX: "hidden",
      }}
    >
      <Seo
        pageTitle="O нас"
        pageDescription="Shake Me digital agency — креативные и опытные специалисты по веб разработке, SMM и дизайну реализуют проекты любой сложности в разумном бюджете. Digital. SMM. Дизайн."
      />
      <MainHeader />
      <AboutUsHeader />
      <div
        sx={{
          paddingTop: "1px",
          backgroundColor: "primary",
        }}
      >
        <AboutUsText
          text="Shake Me — агентство молодых профессионалов. Мы считаем все проекты
      приоритетными: уделяем им максимум внимания и несем ответственность за
      каждую запятую в коде или тексте. Наши клиенты получают только самые
      лучшие решения и не платят за просмотр Youtube нашими сотрудниками."
          header="о нас"
        />
        <ImagesWrapper />
        <AboutUsText
          text={`В команде Shake Me Agency мы собрали самых классных дизайнеров, копирайтеров, разработчиков, менеджеров и smm-специалистов, чтобы сделать любой бизнес в digital лучше.
          Мы гарантируем заказчикам креативные идеи, эффективные продукты и самую нежную поддержку.
          Мы постоянно развиваемся, чтобы следовать актуальным трендам и предлагать бизнесу самые свежие решения.
          `}
          header="наша команда"
        />

        <Tape />
        <div
          sx={{
            backgroundColor: "primary",
            pt: [5],
            pb: [8],
          }}
        >
          <Link
            to="/#services"
            sx={{
              textDecoration: "none",
              display: "flex",
              flexDirection: ["column", "unset"],
              justifyContent: "space-evenly",
              width: ["100%", "70vw"],
              minWidth: ["unset", 767],
              marginX: "auto",
              alignItems: "center",
            }}
          >
            <SectionHeader text="стоимость проекта" />
            <Styled.h2
              // @ts-ignore
              sx={{
                width: "min-content",
                fontSize: "64px",
                color: "transparent",
                margin: [24, 0],
                ...{
                  WebkitTextStrokeWidth: 1,
                  WebkitTextStrokeColor: context.theme.colors.background,
                },
              }}
            >
              Что делаем
            </Styled.h2>
            <Styled.img src={littleArrow} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Page;
