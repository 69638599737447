import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";
import SectionHeader from "src/components/SectionHeader";

type Props = {
  header: string;
  text: string;
};

const Page: React.SFC<Props> = (props) => (
  <div
    sx={{
      width: ["100%", "60%"],
      marginY: [6],
      display: "flex",
      flexDirection: ["column", "unset"],
      justifyContent: "space-between",
      ml: "15vw",
    }}
  >
    <SectionHeader
      text={props.header}
      styles={{
        color: "background",
        // mr: "15vw",
        width: "max-content",
      }}
    />
    <span
      sx={{
        color: "background",
        width: "60%",
      }}
    >
      {props.text}
    </span>
  </div>
);

export default Page;
