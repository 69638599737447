import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, Styled } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import classes from "./styles.module.css";
import Img from "gatsby-image";

const object = {
  alex: "Александра Писаренко",
  andy: "Андрей Шуршев",
  eugen: "Евгения Петайкина",
  maria: "Мария Балышева",
  natalia: "Наталия Поломошнова",
  polukeeva: "Александра Полукеева",
};

export const query = graphql`
  query {
    polukeeva: file(name: { eq: "polukeeva" }) {
      name
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    andy: file(name: { eq: "andy" }) {
      name
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    eugen: file(name: { eq: "eugen" }) {
      name
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    maria: file(name: { eq: "maria" }) {
      name
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    natalia: file(name: { eq: "natalia" }) {
      name
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    alex: file(name: { eq: "alex" }) {
      name
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

const ImagesWrapper: React.SFC<{}> = () => {
  const data = useStaticQuery(query);
  return (
    <div
      sx={{
        overflowX: "scroll",
        height: 500,
        display: "flex",
        marginX: "auto",
        marginY: [6],
      }}
      className={classes.wrapper}
    >
      {Object.keys(data).map((curr, i) => (
        <div
          key={i}
          sx={{
            marginX: 48,
          }}
        >
          <Img fixed={data[curr].childImageSharp.fixed} />
          <h3
            sx={{
              color: "background",
            }}
          >
            {object[curr]}
          </h3>
        </div>
      ))}
    </div>
  );
};

export default ImagesWrapper;
